import ImpactFilter from './ImpactFilter.jsx';

export const createCampaignChangesColumns = (data) => {
  const dates = [...new Set(data.map(item => item.date))].sort((a, b) => new Date(b) - new Date(a));

  const columns = [
    { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true },
    { headerName: "Campaign Name", field: "campaign_name", sortable: true, filter: true, width: 300, pinned: 'left', enableRowGroup: true },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 130, pinned: 'left', enableRowGroup: true }, 
    { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true },    
  ];

  dates.forEach((date, index) => {
    const children = [
      { headerName: "Budget", field: `${date}_budget`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Impressions", field: `${date}_impressions`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Clicks", field: `${date}_clicks`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Spend", field: `${date}_spend`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
      { headerName: "Δ Spend", field: `${date}_delta_spend`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return params.value > 0 ? `+${params.value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : params.value.toLocaleString('en-US', { maximumFractionDigits: 0 });
        }, 
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open',
        aggFunc: 'sum'
      },
      { headerName: "Δ Spend %", field: `${date}_delta_spend_percent`, sortable: true, filter: 'agNumberColumnFilter', width: 120,
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return `${(params.value * 100).toFixed(0)}%`;
        },
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open'
      },
      { headerName: "Sales", field: `${date}_sales`, sortable: true, filter: 'agNumberColumnFilter', width: 120, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
      { headerName: "Δ Sales", field: `${date}_delta_sales`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return params.value > 0 ? `+${params.value.toLocaleString('en-US', { maximumFractionDigits: 0 })}` : params.value.toLocaleString('en-US', { maximumFractionDigits: 0 });
        }, 
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open',
        aggFunc: 'sum'
      },
      { headerName: "Δ Sales %", field: `${date}_delta_sales_percent`, sortable: true, filter: 'agNumberColumnFilter', width: 120,
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return `${(params.value * 100).toFixed(0)}%`;
        },
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'inherit'
        }),
        columnGroupShow: 'open'
      },
      { headerName: "Orders", field: `${date}_orders`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "Units", field: `${date}_units`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open', aggFunc: 'sum' },
      { headerName: "CVR", field: `${date}_cvr`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? `${(params.value * 100).toFixed(1)}%` : '', cellStyle: { textAlign: 'right' }, columnGroupShow: 'open' },
      { headerName: "ACoS", field: `${date}_acos`, sortable: true, filter: 'agNumberColumnFilter', width: 100, valueFormatter: params => params.value ? `${Math.round(params.value * 100)}%` : '', cellStyle: { textAlign: 'right' } },
    ];
    if (index < dates.length - 1) {
      children.push({
        headerName: "Impact",
        field: `${date}_impact`,
        sortable: true,
        filter: ImpactFilter,
        filterParams: {
          suppressFilterButton: true,
        },
        width: 120,
        valueFormatter: params => {
          if (params.value === null || params.value === undefined) return '';
          return `${(params.value * 100).toFixed(1)}%`;
        },
        cellStyle: params => ({
          textAlign: 'right',
        }),
        cellClass: params => params.value >= 0.005 ? 'ag-cell-red' : params.value <= -0.005 ? 'ag-cell-green' : ''
      });
    }

    columns.push({
      headerName: date,
      children: children
    });
  });

  return columns;
};