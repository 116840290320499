import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Typography, Snackbar } from '@mui/material';

const CacheClearingPanel = () => {
  const [messages, setMessages] = useState({
    cosp: '',
    linecache: '',
    linedetailcache: '',
    campaigns: '',
  });

  const [lastClearedTime, setLastClearedTime] = useState(null);
  const [lastCospClearedTime, setLastCospClearedTime] = useState(null);
  const [lastLineDetailClearedTime, setLastLineDetailClearedTime] = useState(null);  
  const [lastCampaignsClearedTime , setLastCampaignsClearedTime] = useState(null)

  const handleClearCOSPCache = useCallback(() => {
    fetch('/api/cosp/clear_cosp_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear COSP cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, cosp: data.message }));
        fetchLastCospClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, cosp: `Error clearing COSP cache: ${error.message}` }));
        setLastCospClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);

  const fetchLastCospClearedTime = useCallback(() => {
    fetch('/api/cosp/get_last_cosp_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastCospClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastCospClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const handleClearLineCache = useCallback(() => {
    fetch('/api/line/clear_line_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear Line Daily cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, lineDaily: data.message }));
        fetchLastClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, lineDaily: `Error clearing Line Daily cache: ${error.message}` }));
        setLastClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);

  const fetchLastClearedTime = useCallback(() => {
    fetch('/api/line/get_last_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const handleClearLineDetailCache = useCallback(() => {
    fetch('/api/line-details/clear_line_detail_cache', { method: 'POST' })
    .then(response => {
      console.log('Response status:', response.status);
      if (!response.ok) {
        return response.text().then(text => { throw new Error(text) });
      }
      return response.json();
    })
    .then(data => {
      setMessages(prev => ({ ...prev, linedetailcache: data.message }));
      fetchLastLineDetailClearedTime(); 
    })
    .catch(error => {
      setMessages(prev => ({ ...prev, linedetailcache: `Error clearing Line Detail cache: ${error.message}` }));
      setLastLineDetailClearedTime(`Error clearing cache: ${error.message}`);
    });
  }, []);

  const fetchLastLineDetailClearedTime = useCallback(() => {
    fetch('/api/line-details/get_last_colp_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastLineDetailClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastLineDetailClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);

  const handleClearCampaignsCache = useCallback(() => {
    fetch('/api/campaigns/clear_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to clear Campaigns cache');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setMessages(prev => ({ ...prev, campaigns: data.message }));
        fetchLastCampaignsClearedTime(); 
      })
      .catch(error => {
        console.log(error.message);
        setMessages(prev => ({ ...prev, campaigns: `Error clearing Campaigns cache: ${error.message}` }));
        setLastCampaignsClearedTime(`Error clearing cache: ${error.message}`);
      });
  }, []);

  const fetchLastCampaignsClearedTime = useCallback(() => {
    fetch('/api/campaigns/get_last_campaigns_cache_clear_time', { method: 'GET' })
      .then(response => {
        if (!response.ok) {  throw new Error('Failed to fetch last cache clear time');  }
        return response.json(); })
      .then(data => {  setLastCampaignsClearedTime(data.last_cleared_time);  })
      .catch(error => {  console.error('Fetch error:', error);
        setLastCampaignsClearedTime(`Error fetching last cleared time: ${error.message}`);  });
  }, []);
  

  const [refreshMessages, setRefreshMessages] = useState({});
  const handleRefreshMaterializedSpapiTable = useCallback(() => {
    setRefreshMessages(prev => ({ ...prev, spapiTable: 'Refreshing Materialized View...' }));
    
    fetch('/api/line/refresh_materialized_spapi_table', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh Materialized SP-API table');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setRefreshMessages(prev => ({ ...prev, spapiTable: data.message }));
      })
      .catch(error => {
        console.error('Error:', error);
        setRefreshMessages(prev => ({ ...prev, spapiTable: `Error refreshing Materialized SP-API table: ${error.message}` }));
      });
  }, []);

  const handleRefreshMaterializedHQCOSP = useCallback(() => {
    setRefreshMessages(prev => ({ ...prev, hqCosp: 'Refreshing Materialized HQ COSP View....' }));
    
    fetch('/api/line/refresh_materialized_HQ_COSP', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to refresh Materialized HQ COSP table');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setRefreshMessages(prev => ({ ...prev, hqCosp: data.message }));
      })
      .catch(error => {
        console.error('Error:', error);
        setRefreshMessages(prev => ({ ...prev, hqCosp: `Error refreshing Materialized HQ COSP table: ${error.message}` }));
      });
  }, []);
  
  useEffect(() => {
    fetchLastClearedTime(); fetchLastCospClearedTime();  fetchLastLineDetailClearedTime(); fetchLastCampaignsClearedTime();
  }, [fetchLastClearedTime, fetchLastCospClearedTime, fetchLastLineDetailClearedTime, fetchLastCampaignsClearedTime]);

  return (
    <Box mt={2} sx={{ padding: 2 }}>
    <Typography variant="h3" gutterBottom>
      Cache Clearing Panel :
    </Typography>
    <Box sx={{ paddingLeft: 4 }} style={{ flexFlow: 'column noWrap'}}>
    <Typography style={{alignSelf: 'flex-end'}} variant='h6'>
      Use these buttons once or twice a day to clear specific cache when its data appears to be outdated.
      After clearing the cache, refresh the page to retrieve the most recent data.
    <Typography  variant='h5' style={{color: '#ff6700' }}>
    Don't use frequently  as it affects all users.
      </Typography>   </Typography> 
      {/* <h5>  <span style={{ fontWeight: 'bold',fontFamily: 'Cursive' }}>  Last Cleared </span> </h5> */}
      <Box mt={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1.6 }}>
      <Typography  
      variant='h5' style={{ fontWeight : 'bold',fontFamily : 'Arial', marginLeft : '253px', marginBottom: '-10px' }}> 
      Last Clearance </Typography>
      <div>   <Button
          variant="contained"   color="secondary"    onClick={handleClearCOSPCache}
          sx={{  width: '240px',  fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', }}
        > 
      Clear COSP Cache
          </Button>   <span style={{ marginLeft : '11.5px', fontFamily: 'Arial' }}> {lastCospClearedTime}  </span>   </div>
     <div>   <Button 
          variant="contained"   color="secondary"   onClick={handleClearLineCache}
          sx={{ width: '240px', fontWeight: 'bold',  }}
      >
        Clear Line Daily/Monthly Cache
        </Button>  <span style={{ marginLeft : '11.5px', fontFamily: 'Arial' }}> {lastClearedTime}  </span> 
        {/* <span style={{ fontFamily: 'cursive' }}> Last Cleared : </span> <span style={{ fontFamily: 'cursive' }}> { lastClearedTime}  </span> */}
        </div>
     <div> <Button 
          variant="contained"   color="secondary"   onClick={handleClearLineDetailCache}
          sx={{  width: '240px',  fontWeight: 'bold',  justifyContent: 'center', alignItems: 'center',}}
        >
          Clear Line Details Cache
        </Button>   <span style={{ marginLeft : '11.5px', fontFamily: 'Arial' }}> {lastLineDetailClearedTime}  </span>
        </div>  
        <div>
        <Button 
          variant="contained"   color="secondary"   onClick={handleClearCampaignsCache}
          sx={{  width: '240px',  fontWeight: 'bold',  justifyContent: 'center', alignItems: 'center',}}
        >
          Clear Campaigns Cache
          </Button>   <span style={{ marginLeft : '11.5px', fontFamily: 'Arial' }}> {lastCampaignsClearedTime}  </span>
          </div>

        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleRefreshMaterializedSpapiTable}
          sx={{ width: '240px',  fontWeight: 'bold',  display: 'flex',justifyContent: 'center',
            alignItems: 'center', mt: 3, bgcolor: 'pink', '&:hover': { bgcolor: '#F33A6A', },
          }}
        >
          Refresh Materialized SP-Api Table
          </Button>
      <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleRefreshMaterializedHQCOSP}
          sx={{  width: '240px',  fontWeight: 'bold',  display: 'flex', justifyContent: 'center',
            alignItems: 'center',  bgcolor: 'pink','&:hover': { bgcolor: '#F33A6A', },  }}
        >
          Refresh Materialized HQ COSP
        </Button>
      </Box>
    </Box>

    {Object.entries(messages).map(([key, message]) => (
      message && (
        <Snackbar
          key={key}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => setMessages(prev => ({ ...prev, [key]: '' }))}
          message={message}
          sx={{  ml :'300px', mb : '100px'}}
        />
      )
    ))}

    {/* Refresh Materialized Messages */}
    {Object.entries(refreshMessages).map(([key, message]) => (
     message && (
      <Snackbar
        key={key}
        open={!!message}
        autoHideDuration={60000}
        onClose={() => setRefreshMessages(prev => ({ ...prev, [key]: '' }))}
        message={message}
        sx={{ ml :'300px', mb: '30px' }}
       />
        )
    ))}
  </Box>
);
};

export default CacheClearingPanel;