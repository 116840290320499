import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter';

const LineDaily = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [lastFilledDay, setLastFilledDay] = useState(30);
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');

  useEffect(() => {
    document.title = "Line Daily | Encasa HQ";
    console.log('Initiating Line Daily data fetch');
    fetch('/api/line/get_line_daily_data')
  .then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.text();   })
  .then(text => {
    console.log('Raw response:', text); 
    return JSON.parse(text);  
  })
  .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          const lastDay = findLastFilledDay(data);
          setLastFilledDay(lastDay);
          setRowData(data);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Daily data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const columns = [
    {
      headerName: 'Revenue Details',
      children: [
    {  headerName: "Country", field: "country",  sortable: true,  filter: true, 
      width: 105,   pinned: 'left',  enableRowGroup: true,
      getQuickFilterText: params => params.value 
    },
    { 
      headerName: "Line",   field: "line",   sortable: true,   filter: true, 
      width: 110,   pinned: 'left',  enableRowGroup: true,
      getQuickFilterText: params => params.value 
    },
    { headerName: "Link", field: "link", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true,cellStyle: { textAlign: 'center' },
    cellRenderer: params => { if (!params.value) { return null; } const url = params.value.startsWith('http') ? params.value : `https://${params.value}`; 
    return React.createElement('a', { href: url, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none'}  }, '🔗');  } },  
    { 
      headerName: "Metric",   field: "metric",   sortable: true, 
      filter: true,   width: 164,   pinned: 'left',
      enableRowGroup: true,
      getQuickFilterText: params => params.value 
    }]},
    {
      headerName: 'Line Info',
      children: [
    {   headerName: "DRI",   field: "dri",   sortable: true,  filter: true,   width: 75,   enableRowGroup: true,  getQuickFilterText: params => params.value  },
    { headerName: "Region",  field: "channel_abb",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
    { headerName: "Channel",  field: "channel",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
    { headerName: "Group",  field: "group",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
    { headerName: "Segment",  field: "segment",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
    
    { 
      headerName: "L7",  field: "l7",   sortable: true,   filter: 'agNumberColumnFilter',  width: 100,  enableRowGroup: true,cellStyle: { textAlign: 'right' },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      getQuickFilterText: params => params.value // Include in quick filter
    },
    { 
      headerName: "P7",   field: "p7",   sortable: true,   filter: 'agNumberColumnFilter',   width: 100,  enableRowGroup: true,cellStyle: { textAlign: 'right' },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      getQuickFilterText: params => params.value // Include in quick filter
    },
    { headerName: 'Delta', field: 'delta', sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: params => ({  textAlign: 'right',  color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black'  }),
    valueGetter: params => { if (!params.data) return null; return (params.data.l7 || 0) - (params.data.p7 || 0); },
    valueFormatter: params => { if (params.value == null) return ''; const formattedValue = Math.abs(params.value).toLocaleString('en-US');
    return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
    }, aggFunc: 'sum'
    },
    { 
      headerName: "L30",  field: "l30",   sortable: true,   filter: 'agNumberColumnFilter',  width: 100,  enableRowGroup: true,cellStyle: { textAlign: 'right' },
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
      getQuickFilterText: params => params.value // Include in quick filter
    }]},
    ...Array.from({ length: 59 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (i+1));  
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      return {
        headerName: formattedDate,
        field: `day_${i+2}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        width: 100,
        cellStyle: { textAlign: 'right' },
        aggFunc: 'sum',
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getQuickFilterText: () => '' // Exclude from quick filter
      };
    }),
  ];
  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setAppliedQuickFilter('');
    // Force the QuickFilter component to reset
    const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
    if (quickFilterInput) {
      quickFilterInput.value = '';
    }
  }, []);

  const handleApplyCustomFilter = useCallback(async () => {
    console.log('Applying custom filter...');
    if (gridRef.current && gridRef.current.api) {
      console.log('Grid API is available');
      const filterModel = {
        metric: {
          filterType: 'set',
          values: ['Net Profit'],
          filter: ['Net Profit']
        },
        [`day_${lastFilledDay}`]: {
          filterType: 'number',
          type: 'notBlank'
        }
      };
      console.log('Filter model:', filterModel);
      gridRef.current.api.setFilterModel(filterModel);
      
      gridRef.current.api.onFilterChanged();
      console.log('Filter applied');

      // Sort by last filled day column
      const sortModel = [
        {
          colId: `day_${lastFilledDay}`,
          sort: 'desc'
        }
      ];
      console.log('Sort model:', sortModel);
      gridRef.current.api.applyColumnState({ state: sortModel });
      console.log('Sorting applied');
    } else {
      console.log('Grid API is not available');
    }
  }, [lastFilledDay]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: null,
    };
  }, []);

  const handleQuickFilterChange = useCallback((filterText) => {
    setAppliedQuickFilter(filterText);
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Daily</Typography>
        <Box>
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleApplyCustomFilter}
            style={{ marginRight: '10px' }}
          >
            Filter to Profits
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              cellRenderer: 'agCellRendererHtml',
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={[50, 100, 500, 1000]}
            rowHeight={25} // Set a smaller row height
            grandTotalRow="bottom"
            groupDefaultExpanded={0} // Collapse all groups by default
            autoGroupColumnDef={{
              headerName: 'Group',
              minWidth: 250,
              cellRendererParams: {
                suppressCount: true,
              },
            }}
            quickFilterText={appliedQuickFilter}
            cacheQuickFilter={true}
          />
        </div>
      )}
    </div>
  );
};

// Add this helper function at the end of the component, before the return statement
const findLastFilledDay = (data) => {
  for (let i = 1; i <= 60; i++) {
    if (data.some(row => row[`day_${i}`] !== undefined && row[`day_${i}`] !== null)) {
      return i;
    }
  }
  return 1; // Default to 1 if no filled day is found
};

export default LineDaily;