import React, { useState } from 'react';
import { Button, TextField, Tooltip, IconButton, Box, Tabs, Tab, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import SpApiGetData, { GetLatestStatusComponent } from '../spapi_getlistingdata/spapi_getlistingsdata.jsx';
import { SpApiDataProvider } from '../spapi_getlistingdata/spapiDataContext.js';

const CatalogTools = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [selectedCountryDelete, setSelectedCountryDelete] = useState('');
  const [selectedCountryCreate, setSelectedCountryCreate] = useState('');
  const [selectedCountryGroup, setSelectedCountryGroup] = useState('');
  const [selectedCountryGroupMultiple, setSelectedCountryGroupMultiple] = useState('');
  const [selectedCountryCatalog, setSelectedCountryCatalog] = useState('');

  const [parentSkuDelete, setParentSkuDelete] = useState('');
  // const [lineCreate, setLineCreate] = useState('');
  const [ParentSkuCreate, setParentSkuCreate] = useState('');
  const [parentSkuGroup, setParentSkuGroup] = useState('');
  const [parentSkuGroupMultiple, setParentSkuGroupMultiple] = useState('');
  const [lineCatalog, setLineCatalog] = useState('');

  const [deleteFeedback, setDeleteFeedback] = useState({ message: '', style: {} });
  const [createFeedback, setCreateFeedback] = useState({ message: '', style: {} });
  const [groupFeedback, setGroupFeedback] = useState({ message: '', style: {} });
  const [groupFeedbackMultiple, setGroupFeedbackMultiple] = useState({ message: '', style: {} });
  const [catalogFeedback, setCatalogFeedback] = useState({ message: '', style: {} });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);};
  // const [selectedCountryGroupXO, setSelectedCountryGroupXO] = useState('');
  // const [parentSkuGroupXO, setParentSkuGroupXO] = useState('');
  // const [groupFeedbackXO, setGroupFeedbackXO] = useState({ message: '', style: {} });

  const [selectedCountryStatus, setSelectedCountryStatus] = useState('');
  const [lineStatus, setLineStatus] = useState('');
  const [getStatus, setGetStatus] = useState({ message: '', style: {} });

    //""""" Listing Data Variables """""//
  const [countryListingData, setCountryListingData] = useState('');
  const [parentSkuListingData, setParentSkuListingData] = useState('');
  const [listingData, setListingData] = useState([]);
  const [listingDataFeedback, setListingDataFeedback] = useState({ message: '', style: {} });

  // const [countryListingAPI, setCountryListingAPI] = useState('');
  // const [skusListingAPI, setSkusListingAPI] = useState('');
  // const [includeListingAPI, setIncludeListingAPI] = useState('');
  // const [feedbackListingAPI, setFeedbackListingAPI] = useState({ message: '', style: {} });
  // const [getlistingAPI, setgetListingAPI] = useState(null);

  const handleGetLatestStatus = async () => {
    console.log("Initiating Get Latest Status request");
    setGetStatus({ message: 'Fetching latest status...', style: { color: 'blue' } });
    try {
      const processedLines = lineStatus
        .split(/[\n, ]/)
        .map(line => line.trim())
        .filter(line => line);

      console.log("Processed lines:", processedLines);
      console.log("Selected country:", selectedCountryStatus);

      const response = await fetch('/api/spapi_getdata/sku/latest_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountryStatus,
          lines: processedLines
        })
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error(errorText || response.statusText);
      }

      const result = await response.json();
      console.log("Success response:", result);

      setGetStatus({ message: result.message, style: { color: 'green' } });
    } catch (error) {
      console.error('Error in handleGetLatestStatus:', error);
      setGetStatus({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };

  const handleDeleteSku = async () => {
    setDeleteFeedback({ message: 'Submitting SKU deletion ...', style: { color: 'blue' } });
    try {
      const response = await fetch('/api/amazon_catalog/sku/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountryDelete,
          skus: [parentSkuDelete]
        })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setDeleteFeedback({ message: result.message, style: { color: 'green' } });
      } else {
        setDeleteFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setDeleteFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };
  // const handleDeleteSku = async () => {
  //   setDeleteFeedback({ message: 'Submitting SKU deletion ...', style: { color: 'blue' } });
  //   try {
  //     const response = await fetch('/api/amazon_catalog/sku/delete', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         country: selectedCountryDelete,
  //         skus: [parentSkuDelete]
  //       })
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to delete SKU');
  //     }

  //     const result = await response.json();
  //     setDeleteFeedback({ message: 'SKU deletion *submitted* successful', style: { color: 'green' } });
  //   } catch (error) {
  //     setDeleteFeedback({ message: `Error deleting SKU: ${error.message}`, style: { color: 'red' } });
  //   }
  // };

  const handleCreateParentSku = async () => {
    setCreateFeedback({ message: 'Submitting SKU creation ...', style: { color: 'blue' } });
    try {
      const processedParentSkus = ParentSkuCreate
        .split(/[\n, ]/) // Split by newlines, commas, and spaces
        .map(sku => sku.trim()) // Trim spaces
        .filter(sku => sku); // Remove empty strings
  
      const response = await fetch('/api/amazon_catalog/sku/create_parent_sku', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountryCreate,
          parent_skus: processedParentSkus
        })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setCreateFeedback({ message: result.message, style: { color: 'green' } });
      } else {
        setCreateFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setCreateFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };
  
  const handleGroupChildSkus = async () => {
    setGroupFeedback({ message: 'Submitting SKU grouping ...', style: { color: 'blue' } });
    try {
      const response = await fetch('/api/amazon_catalog/sku/regroup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountryGroup,
          parent_sku: parentSkuGroup
        })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setGroupFeedback({ message: result.message, style: { color: 'green' } });
      } else {
        setGroupFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setGroupFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };

  const handleGroupChildSkusMultiple = async () => {
    setGroupFeedbackMultiple({ message: 'Submitting SKU grouping (Encasa XO)...', style: { color: 'blue' } });
      
    try {
      const processedParentSkus = parentSkuGroupMultiple
        .split(/[\n, ]/)  
        .map(parent_sku => parent_sku.trim()) 
        .filter(parent_sku => parent_sku);  

      const response = await fetch('/api/amazon_catalog/sku/regroup_multiple_parent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountryGroupMultiple,
          parent_skus: processedParentSkus
        })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setGroupFeedbackMultiple({ message: result.message, style: { color: 'green' } });
      } else {
        setGroupFeedbackMultiple({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setGroupFeedbackMultiple({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };
  
  const handleCatalogSku = async () => {
    setCatalogFeedback({ message: 'Preparing catalog listing file ...', style: { color: 'blue' } });
    try {
      const processedLines = lineCatalog
        .split(/[\n, ]/) // Split by newlines, commas, and spaces
        .map(line => line.trim()) // Trim spaces
        .filter(line => line); // Remove empty strings
  
      const response = await fetch('/api/amazon_catalog/sku/catalog_upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: selectedCountryCatalog,
          lines: processedLines
        })
      });
  
      const result = await response.json();

      if (response.ok) {
        setCatalogFeedback({ message: result.message, style: { color: 'green' } });
      } else {
        setCatalogFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setCatalogFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  }; 
   
  const handleGetListingData = async () => {
    setListingDataFeedback({ message: 'Fetching listing data...', style: { color: 'blue' } });
    try {
      const response = await fetch('/api/amazon_catalog/sku/listing_data_of_regroup_multiple_parent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: countryListingData,
          parent_skus: parentSkuListingData.split(',').map(sku => sku.trim())
        })
      });

      const result = await response.json();
      if (response.ok) {
        // Make sure each row has a unique id
        const dataWithIds = result.data.map((row, index) => ({
          ...row,
          id: index, // or use a unique field from your data
        }));
        setListingData(dataWithIds);
        setListingDataFeedback({ message: 'Data fetched successfully', style: { color: 'green' } });
      } else {
        setListingDataFeedback({ message: `Error: ${result.error}`, style: { color: 'red' } });
      }
    } catch (error) {
      setListingDataFeedback({ message: `Error: ${error.message}`, style: { color: 'red' } });
    }
  };

  const handleExportJsonPayload = async () => {
    setListingDataFeedback({ message: 'Exporting JSON payload...', style: { color: 'blue' } });
    try {
      const response = await fetch('/api/amazon_catalog/sku/export_json_payload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          country: countryListingData,
          parent_skus: parentSkuListingData.split(',').map(sku => sku.trim())
        })
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || response.statusText);
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'amazon_payload.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
  
      setListingDataFeedback({ message: 'JSON Payload exported successfully', style: { color: 'green' } });
    } catch (error) {
      console.error('Error in handleExportJsonPayload:', error);
      setListingDataFeedback({ message: `Failed to export JSON Payload: ${error.message}`, style: { color: 'red' } });
    }
  };

  const columns = [
    { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 150 },
    { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 150 },
    { headerName: "Asin", field: "asin", sortable: true, filter: true, width: 150 },
    { headerName: "Pcs Pack", field: "pcs_pack", sortable: true, filter: true, width: 120, cellStyle: { textAlign: 'center' } },
    { headerName: "Size Name", field: "size_name", sortable: true, filter: true, width: 150 },
    { headerName: "Color", field: "color", sortable: true, filter: true, width: 150 },
    { headerName: "Variation Theme", field: "Variation_Theme", sortable: true, filter: true, width: 180 },
    { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 200 },
    { headerName: "Parent SKU", field: "parent_sku", sortable: true, filter: true, width: 150 },
    { headerName: "Parent Product Type", field: "parent_producttype", sortable: true, filter: true, width: 200 },
  ];
   
  <DataGrid
    rows={listingData}  columns={columns}
    pageSize={5}  rowsPerPageOptions={[5]}
    getRowId={(row) => row.sku + row.asin}  
  />

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& .MuiTab-root': {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      minWidth: '150px',
    },
  }));
  
  const StyledTab = styled(Tab)(({ theme }) => ({
    marginRight: theme.spacing(2),
    '&.Mui-selected': {
    fontWeight: 'bold', color: '#03a9f4', 
    // backgroundColor: '#f0f0f0'
  }
  }));

  const getButtonsSx = (options = {}) => {
    const { width } = options;
  
    return {
      fontWeight: 'bold',  mt: 1,
      justifyContent: 'center',  alignItems: 'center',
      bgcolor: '#00b0ff',
      '&:hover': { bgcolor: '#ff5730' },
      ...(width && { width }),  
    };
  };
  
  
  return (
    <SpApiDataProvider>
   <Box m="5px">
     <Typography variant="h5" component="h1" gutterBottom style={{ fontWeight: 'bold', marginBottom: '0px' }}>
        GROUPING
      </Typography>
      <StyledTabs 
        value={activeTab} 
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="primary"
      >
        <StyledTab label="Catalog Tools" />
        <StyledTab label="Latest Status" />
        <StyledTab label="Data Preview" />
        <StyledTab label="Listing Issues" />
      </StyledTabs>
  
        {activeTab === 0 && (
          <Box mt={0}>
  
        <div style={{ margin: '20px 10px'  }}>
          <h2 style={{ marginBottom: '5px'}}>Delete SKUs</h2>
          <TextField
            label="Country"
            value={selectedCountryDelete}
            onChange={(e) => setSelectedCountryDelete(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <TextField
            label="SKUs"
            value={parentSkuDelete}
            onChange={(e) => setParentSkuDelete(e.target.value)}
            style={{ marginRight: '10px'}}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteSku} sx={getButtonsSx({ width: '100px' })}
          >
            DELETE
          </Button>
          {deleteFeedback.message && <p style={deleteFeedback.style}>{deleteFeedback.message}</p>}
        </div>
  
        <div style={{ margin: '20px 10px' }}>
          <h2 style={{ marginBottom: '5px'}}>Create Parent SKUs
        <Tooltip title="Specify the details in 'Line Details', Parent SKUs can be created in bulk using comma seprated values" placement="right">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoIcon fontSize="medium" style={{ color: '#4682b4', fontSize : '18px'}}/>
          </IconButton>
        </Tooltip>
          </h2>
          <TextField
            label="Country"
            value={selectedCountryCreate}
            onChange={(e) => setSelectedCountryCreate(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <TextField
            label="Parent SKUs"
            value={ParentSkuCreate}
            onChange={(e) => setParentSkuCreate(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCreateParentSku}
            sx={getButtonsSx({ width: '100px' })}
          >
            CREATE
          </Button>
          {createFeedback.message && <p style={createFeedback.style}>{createFeedback.message}</p>}
        </div>
  
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 10px',gap: '20px' }}>
      <div style={{ flex: 1 }}>
        <h2 style={{ display: 'flex', marginBottom : '5px', alignItems: 'center' }}>
        Group Parent SKU
        <Tooltip title="Specify the required 'Child_Brand' in last column of 'Line Details' as per the brand name available in the sp_api data" placement="right">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoIcon fontSize="medium" style={{ color: '#4682b4' , fontSize : '18px'}}/>
          </IconButton>
        </Tooltip>
        </h2>
        <TextField
          label="Country"
          value={selectedCountryGroup}
          onChange={(e) => setSelectedCountryGroup(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <TextField
          label="Parent SKU"
           value={parentSkuGroup}
          onChange={(e) => setParentSkuGroup(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleGroupChildSkus} sx={getButtonsSx({ width: '100px' })}
        >
          GROUP
        </Button>
        {groupFeedback.message && <p style={groupFeedback.style}>{groupFeedback.message}</p>}
      </div>
  
      <div style={{ flex: 1 }}>
        <h2 style={{ marginBottom: '5px'}}>Group Multiple Parent SKUs</h2>
        <TextField
          label="Country"
          value={selectedCountryGroupMultiple}
          onChange={(e) => setSelectedCountryGroupMultiple(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <TextField
          label="Parent SKUs"
          value={parentSkuGroupMultiple}
          onChange={(e) => setParentSkuGroupMultiple(e.target.value)}
          style={{ marginRight: '10px' }}
          />
        <Button
          variant="contained"
          color="secondary"
           onClick={handleGroupChildSkusMultiple} sx={getButtonsSx({ width: '100px' })}
                     >
            GROUP MP
          </Button>
          {groupFeedbackMultiple.message && <p style={groupFeedbackMultiple.style}>{groupFeedbackMultiple.message}</p>}
        </div>
      </div>
  
            <div style={{ margin: '20px 10px' }}>
              <h2 style={{ marginBottom: '5px'}}>Catalog Upload</h2>
              <TextField
                label="Country"
                value={selectedCountryCatalog}
                onChange={(e) => setSelectedCountryCatalog(e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <TextField
                label="Lines"
                value={lineCatalog}
                onChange={(e) => setLineCatalog(e.target.value)}
                style={{ marginRight: '10px' }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCatalogSku}  sx={getButtonsSx()}
              >
                UPLOAD CATALOG
              </Button>
              {catalogFeedback.message && <p style={catalogFeedback.style}>{catalogFeedback.message}</p>}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 10px', gap: '10px' }}>
          <div style={{ flex: 1 }}> 
            <h2 style={{ marginBottom: '5px'}}>Get Latest Status</h2>
            <TextField
              label="Country"
              value={selectedCountryStatus}
              style={{ marginRight: '10px' }}
              onChange={(e) => {
                console.log("Country changed:", e.target.value);
                setSelectedCountryStatus(e.target.value);
              }}
            />
            <TextField
              label="Lines"
              value={lineStatus}
              style={{ marginRight: '10px' }}
              onChange={(e) => {
                console.log("Lines changed:", e.target.value);
                setLineStatus(e.target.value);
              }}
              placeholder="Enter lines separated by commas, spaces, or newlines"
            />
            <Button
              variant="contained"  color="secondary"
              onClick={handleGetLatestStatus}  sx={getButtonsSx({ width: '100px' })}
            >
              GET STATUS
            </Button>
            {getStatus.message && <p style={getStatus.style}>{getStatus.message}</p>}
          </div>  </div>  
          </Box>
          )}
         {activeTab === 1 && < GetLatestStatusComponent />}
         {activeTab === 2 && (
       <Box mt={1}>
         <h3 style={{ marginBottom: '10px' }}>Review the data sent to Amazon for grouping via Catalog Tool</h3>
         <Box display="flex" alignItems="center" mb={2}>
         <TextField
          label="Country"
          value={countryListingData}
          onChange={(e) => setCountryListingData(e.target.value)}
          style={{ marginRight: '10px' }}
          // style={{ marginRight: '10px' , backgroundColor: '#f0f8ff', fontWeight:'bold' }}
         />
         <TextField
          label="Parent SKUs (comma-separated)"
          value={parentSkuListingData}
          onChange={(e) => setParentSkuListingData(e.target.value)}
          style={{ marginRight: '10px', width : '500px'}} 
          // style={{ backgroundColor: '#f0f8ff' }} sx={{'& .MuiInputLabel-root': { color: '#616161'}}}
         />
         <Button
          variant="contained"
          color="primary"
          onClick={handleGetListingData}
          style={{ marginRight: '10px'}}
         >
          Get Listing Data
         </Button>
         <Button
          variant="contained"
          color="primary"
          onClick={handleExportJsonPayload}
         >
          Export JSON Schema
         </Button>
       </Box>
      
        {listingDataFeedback.message && (
         <Box mt={0} mb={0}>
           <Typography style={listingDataFeedback.style}>
             {listingDataFeedback.message}
           </Typography>
         </Box>
       )}
      
      <div className="ag-theme-alpine" style={{ height: 530, width: '100%'  }}>
      <AgGridReact
       columnDefs={columns}
       rowData={listingData}
       pagination={true}
       paginationPageSize={100}
       suppressCellSelection={true}
       onGridReady={(params) => {
         params.api.sizeColumnsToFit();
       }}
     />
   </div>
   </Box>
    )}
    {activeTab === 3 && < SpApiGetData />}
    </Box>
     </SpApiDataProvider>
  );
  };

export default CatalogTools;